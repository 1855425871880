import { render, staticRenderFns } from "./Bankid.vue?vue&type=template&id=51c522c4&"
import script from "./Bankid.vue?vue&type=script&lang=js&"
export * from "./Bankid.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\RobertWiberg\\source\\repos\\server-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('51c522c4')) {
      api.createRecord('51c522c4', component.options)
    } else {
      api.reload('51c522c4', component.options)
    }
    module.hot.accept("./Bankid.vue?vue&type=template&id=51c522c4&", function () {
      api.rerender('51c522c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Invite/Activate/SE/Bankid.vue"
export default component.exports