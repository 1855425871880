var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "mt-2" }, [
    !_vm.loading
      ? _c("div", [
          _c(
            "p",
            [
              _c(
                "b-overlay",
                { attrs: { show: _vm.redirect, rounded: "sm" } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-fill btn-primary btn-block",
                      attrs: { disabled: !_vm.checkAccepted },
                      on: { click: _vm.inviteSubmit },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("NEXT")) + "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-secondary btn-block mt-2",
                      attrs: { to: { name: "Login" } },
                    },
                    [_vm._v(_vm._s(_vm.$t("BACK")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }