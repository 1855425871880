var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    !_vm.loading
      ? _c(
          "div",
          [
            _c("b-overlay", { attrs: { show: _vm.redirect, rounded: "sm" } }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-fill mt-2 btn-block btn-primary",
                  attrs: { disabled: !_vm.checkAccepted },
                  on: { click: _vm.inviteSubmit },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("LOGIN")) + "\n      ")]
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.loading && _vm.authRef
      ? _c("div", { staticClass: "p-4 text-center" }, [
          !_vm.showOnThisDevice
            ? _c(
                "div",
                [
                  _c("b-img", {
                    attrs: {
                      src: _vm.frejaQrCode,
                      alt: _vm.$t("AUTH_METHODS.freja") + " QR",
                    },
                  }),
                  _vm.hintMessage != "outstandingTransaction"
                    ? _c("div", [_c("h5", [_vm._v(_vm._s(_vm.statusMessage))])])
                    : _vm._e(),
                  _c(
                    "a",
                    { attrs: { variant: "primary", href: _vm.urlDevice } },
                    [_vm._v(_vm._s(_vm.$t("SAME_DEVICE")))]
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "secondary" },
                          on: { click: _vm.cancelSubmit },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("CANCEL")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showOnThisDevice
            ? _c("div", { staticClass: "mt-2 mb-2" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary btn-fill text-white",
                    attrs: { variant: "primary", href: _vm.urlDevice },
                  },
                  [_vm._v(_vm._s(_vm.$t("SAME_DEVICE")))]
                ),
                _c("br"),
                _c("br"),
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.otherDevice.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n          " + _vm._s(_vm.$t("OTHER_DEVICE")))]
                ),
                _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "secondary" },
                        on: { click: _vm.cancelSubmit },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("CANCEL")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }