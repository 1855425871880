<template>
  <section>
    <div v-if="error">
      <div class="box-content">
        <div class="mt-4 mb-4 text-center">
          <i class="fal fa-exclamation-circle"></i>
          {{ $t("ERROR.COULD_NOT_USE_INVITE") }}
        </div>
      </div>
    </div>
    <div v-if="!error">
      <div v-if="!user.information">
        <div v-if="information">
          <div class="text-center">
            <b-img
              class="org-logo"
              v-if="information.image_uuid"
              fluid
              :src="organisationImage"
              :alt="information.organisation_name"
            ></b-img>
            <b-img
              class="logo"
              v-if="information.image_uuid == null"
              fluid
              src="/img/sefos.png"
              alt
            ></b-img>
          </div>

          <div class="box-content" v-if="information.auth_type != ''">
            <div v-if="loading == false">
              <h4 class="w-medium pt-0 mt-0">{{ $t("INVITE.TITLE") }}</h4>
              <dl class="clearfix">
                <dt>{{ $t("ORGANISATION.TITLE") }}</dt>
                <dd>{{ information.organisation_name }}</dd>
                <dt>{{ $t("NAME") }}</dt>
                <dd>{{ information.name }}</dd>
                <dt>{{ $t("EMAIL") }}</dt>
                <dd>{{ information.email }}</dd>
                <dt v-if="information.title">{{ $t("TITLE") }}</dt>
                <dd v-if="information.title">{{ information.title }}</dd>
                <dt v-if="information.department">{{ $t("DEPARTMENT") }}</dt>
                <dd v-if="information.department">{{ information.department }}</dd>
                <dt>{{ $t("AUTH_METHODS.TITLE") }}</dt>
                <dd v-if="information.auth_type != 'custom'">{{ $t("AUTH_METHODS." + information.auth_type) }}</dd>
                <dd v-if="information.auth_type == 'custom'">{{ information.custom_name }}</dd>               
              </dl>
            </div>

            <div class="form-group mt-2" v-if="!loading">

              <p v-if="AgreementsDTO">
                <a v-if="AgreementsDTO.privacy_policy"
                target="_blank"
                :href="AgreementsDTO.privacy_policy"
                >{{ $t("INVITE.privacy_policy") }}</a
              ><br v-if="AgreementsDTO.privacy_policy" />
                <a
                  target="_blank"
                  :href="AgreementsDTO.data_processing"
                  >{{ $t("INVITE.data_processing_agreement") }}</a
                >
                <br /><a
                  target="_blank"
                  :href="AgreementsDTO.end_user"
                  >{{ $t("INVITE.end_user_agreement") }}</a
                >
               
              <b-form-checkbox
              class="mt-2"
              id="checkbox-1"
              :disabled="!AgreementsDTO"
              v-model="accepted"
              name="checkbox-1"
              switch
              value="1"
              unchecked-value="0"
            >
              {{ $t("INVITE.ACCEPT_AGREEMENT") }}
            </b-form-checkbox>
              </p>
            </div>

            <InvitePassword
              :email="information.email"
              @loading="setLoading"
              @activated="activated"
              :accepted="checkAccepted"
              :code="code"
              v-if="information.auth_type == 'password'"
            ></InvitePassword>
            <InvitePasswordSms
              :email="information.email"
              @loading="setLoading"
              @activated="activated"
              :accepted="checkAccepted"
              :code="code"
              v-if="information.auth_type == 'password-sms'"
            ></InvitePasswordSms>
            <InviteSiths
              :auth_method="information.auth_method"
              :email="information.email"
              @loading="setLoading"
              @activated="activated"
              :accepted="checkAccepted"
              :code="code"
              v-if="information.auth_type == 'siths-card'"
            ></InviteSiths>
            <InviteSefosCertificate
              :email="information.email"
              @loading="setLoading"
              @activated="activated"
              :accepted="checkAccepted"
              :code="code"
              v-if="information.auth_type == 'sefos-certificate'"
            ></InviteSefosCertificate>
            <InviteSeEid
              :auth_method="information.auth_method"
              :email="information.email"
              @loading="setLoading"
              @activated="activated"
              :accepted="checkAccepted"
              :code="code"
              v-if="information.auth_type == 'se-eid'"
            ></InviteSeEid>
            <InviteFrejaOrgId
              :auth_method="information.auth_method"
              :email="information.email"
              @loading="setLoading"
              @activated="activated"
              :accepted="checkAccepted"
              :code="code"
              v-if="information.auth_type == 'freja-org'"
            ></InviteFrejaOrgId>
            <InviteCustom
              :auth_method="information.auth_method"
              :email="information.email"
              @loading="setLoading"
              @activated="activated"
              :accepted="checkAccepted"
              :code="code"
              v-if="information.auth_type == 'custom'"
            ></InviteCustom>

            <b-form-invalid-feedback :state="checkAccepted">
              {{ $t("INVITE.NEED_TO_ACCEPT_AGREEMENT") }}
            </b-form-invalid-feedback>
          
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import InviteSeEid from "@/components/Invite/Activate/SE/eID";
import InvitePassword from "@/components/Invite/Activate/Password";
import InvitePasswordSms from "@/components/Invite/Activate/PasswordSms";
import InviteSiths from "@/components/Invite/Activate/Siths/Siths";
import InviteSefosCertificate from "@/components/Invite/Activate/SefosCertificate";
import InviteFrejaOrgId from "@/components/Invite/Activate/FrejaOrgID.vue";
import InviteCustom from "@/components/Invite/Activate/Custom.vue";
export default {
  components: {
    InviteSeEid,
    InvitePassword,
    InvitePasswordSms,
    InviteSiths,
    InviteSefosCertificate,
    InviteFrejaOrgId,
    InviteCustom
  },
  props: ["verify", "code", "auth_uuid"],
  data() {
    return {
      error: false,
      loading: true,
      image: "img/login-image.png",
      information: null,
      invitationCode: "",
      accepted: 0,
      password: "",
      password2: "",
      passwordFieldType: "password",
      finished: false,
      AgreementsDTO: null
    };
  },
  methods: {
    async init(){      
      if (!this.user.information) {
        await this.GetAgreements();
        await this.getInviteInformation();
      }
    },
    setLoading: function(val) {
      this.$cookies.set("email", this.information.email, Infinity, "", "", true, "None");
      this.$cookies.set("auth_type", this.information.auth_type, Infinity, "", "", true, "None");
      this.$cookies.set("auth_method", this.information.auth_method, Infinity, "", "", true, "None");
      this.loading = val;
    },
    async getInviteInformation() {
      this.loading = true;
      try {
        let response = await this.$http
          .get(this.user.hostname + "/invite/info/" + this.code);
        this.information = response.data;
        this.loading = false;
        this.$i18n.locale = response.data.lang;
      } catch {
        this.error = true;
        console.log("Could not getInviteInformation");
      }
    },
    async GetAgreements(){
      try {
        let response = await this.$http
          .get(this.user.hostname + "/agreements");
        this.AgreementsDTO = response.data;
      } catch {
        console.log("Could not GetAgreements");
      }
    },
    async authenticated(token) {
      this.information = null;
      this.auth_uuid = "";
      this.$cookies.set("token", token);      
      if (this.$store.state.user.redirect_url != "") {
        let gotoUrl = this.$store.state.user.redirect_url;
        await this.$store.dispatch("user/setRedirectUrl", "");
        window.location.href = gotoUrl;
      } else {
        this.$router.push({ name: "Messages.Inbox" });
      }
    },
    async activated(token) {   
      let self = this;           
      this.$cookies.set("token", token);
      setTimeout(function(){ self.$router.push({ name: "Messages.Inbox" }); }, 500);
    },
    async verifyAuthentication() {
      if(!this.user.information)
      {
        try {
          let result = await this.$http.get(this.user.hostname + "/invite/activate/result/" + this.auth_uuid);
          this.authenticated(result.data.token);
          this.auth_uuid = "";        
        } catch {
          this.init();
          this.loading = false;
          console.log("Could not getMethods");
        }   
      }
    },
  },
  computed: {
    organisationImage() {
      return (
        this.user.hostname + "/organisation/image/" +
        this.information.organisation_id +
        "?uuid=" +
        this.information.image_uuid
      );
    },
    checkAccepted() {
      return this.accepted == 1 && this.AgreementsDTO != null;
    },
    authenticationImage() {
      if (this.auth_method == "") {
        return "/img/auth/" + this.information.auth_type + ".png";
      } else {
        return "/img/auth/" + this.information.auth_method + ".png";
      }
    },
  },
  mounted: function() {
    //console.log("Invite mounted");
    if(this.auth_uuid != undefined)
    {
      //console.log(this.auth_uuid);
      this.verifyAuthentication();
    }else{
      this.init();
    }    
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}
</style>
