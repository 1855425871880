var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
        _vm.step == 1
          ? _c(
              "div",
              [
                _c("div", { staticClass: "mt-2" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("INVITE.NEED_TO_SET_PASSWORD_SMS")) +
                      "\n      "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "form-group mt-2" },
                  [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v(_vm._s(_vm.$t("PASSWORD"))),
                    ]),
                    _c("b-form-input", {
                      attrs: {
                        trim: "",
                        type: "password",
                        autocomplete: "off",
                      },
                      on: { input: _vm.acceptData },
                      model: {
                        value: _vm.password1,
                        callback: function ($$v) {
                          _vm.password1 = $$v
                        },
                        expression: "password1",
                      },
                    }),
                    _c(
                      "b-form-invalid-feedback",
                      { attrs: { state: _vm.errors.length == 0 } },
                      _vm._l(_vm.errors, function (item, index) {
                        return _c("div", { key: index }, [
                          _vm._v("* " + _vm._s(item)),
                        ])
                      }),
                      0
                    ),
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v(_vm._s(_vm.$t("RETYPE_PASSWORD"))),
                    ]),
                    _c("b-form-input", {
                      attrs: {
                        trim: "",
                        type: "password",
                        autocomplete: "off",
                      },
                      model: {
                        value: _vm.password2,
                        callback: function ($$v) {
                          _vm.password2 = $$v
                        },
                        expression: "password2",
                      },
                    }),
                    _c(
                      "b-form-invalid-feedback",
                      { attrs: { state: !_vm.password_not_matching } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("ERROR.PASSWORD_NOT_MATCHED")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-fill btn-block btn-primary",
                    attrs: { disabled: !_vm.showNextButton },
                    on: { click: _vm.inviteSubmit },
                  },
                  [_vm._v("\n        " + _vm._s(_vm.$t("NEXT")) + "\n      ")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "btn-block btn btn-secondary",
                    attrs: { to: { name: "Login" } },
                  },
                  [_vm._v(_vm._s(_vm.$t("BACK")))]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.step == 2
          ? _c("div", [
              _c("div", { staticClass: "text-center" }, [
                _c("div", { staticClass: "caption-title" }, [
                  _vm._v(_vm._s(_vm.$t("SMS.SPECIFY_CODE"))),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sms_code,
                      expression: "sms_code",
                    },
                  ],
                  staticClass: "mt-2 text-center form-control",
                  attrs: {
                    type: "tel",
                    name: "code",
                    required: "",
                    placeholder: _vm.$t("SMS.SPECIFY_CODE_PLACEHOLDER"),
                  },
                  domProps: { value: _vm.sms_code },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.authenticateIdentifier.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.sms_code = $event.target.value
                    },
                  },
                }),
                _c("div", { staticClass: "mt-4" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-fill btn-block btn-primary",
                      attrs: { disabled: _vm.disableVerify },
                      on: { click: _vm.authenticateIdentifier },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("VERIFY_BTN")) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "mt-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "mt-2 btn btn-block btn-secondary",
                      on: { click: _vm.cancelSubmit },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("CANCEL")) + "\n        "
                      ),
                    ]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }