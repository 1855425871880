var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
        _c(
          "button",
          {
            staticClass: "btn btn-fill mt-2 btn-block btn-primary",
            attrs: { disabled: !_vm.checkAccepted },
            on: { click: _vm.inviteSubmit },
          },
          [
            _c("img", {
              staticClass: "authIcon",
              attrs: {
                src: "/img/auth/net-id-enterprise.png",
                alt: _vm.$t("AUTH_METHODS.net-id-enterprise"),
              },
            }),
            _vm._v(
              " " +
                _vm._s(_vm.$t("START")) +
                " " +
                _vm._s(_vm.$t("AUTH_METHODS.net-id-enterprise")) +
                "\n      "
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }