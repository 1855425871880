var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      !_vm.loading
        ? _c(
            "b-media",
            {
              staticClass: "mb-2",
              attrs: { "vertical-align": "center" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "aside",
                    fn: function () {
                      return [
                        _c("b-img", {
                          staticClass: "mt-2",
                          attrs: {
                            src: "/img/auth/siths-card.png",
                            alt: _vm.$t("AUTH_METHODS.siths-card"),
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                479534894
              ),
            },
            [
              _c("p", { staticClass: "mt-4" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("INVITE.NEED_TO_AUTHENTICATE")) +
                    "\n      " +
                    _vm._s(_vm.$t("AUTH_METHODS.siths-card")) +
                    "\n    "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.options.length != 0
        ? _c(
            "div",
            [
              _vm.enterprise && _vm.enabled_net_id_enterprise
                ? _c("NetIDEnterprise", {
                    attrs: { accepted: _vm.accepted, code: _vm.code },
                    on: {
                      loading: _vm.setLoading,
                      setaccess: _vm.setaccess,
                      activated: _vm.activated,
                    },
                  })
                : _vm._e(),
              !_vm.loading &&
              _vm.enabled_net_id_access &&
              _vm.enabled_net_id_enterprise &&
              _vm.enterprise
                ? _c("div", [
                    _c(
                      "p",
                      { staticClass: "mt-2 mb-2 strikethrough text-center" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("AUTH_METHODS.DO_YOU_HAVE_NET_ID_ACCESS")
                            )
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.access && _vm.enabled_net_id_access
                ? _c("NetIDAccess", {
                    attrs: {
                      disabled: !_vm.checkAccepted,
                      accepted: _vm.accepted,
                      code: _vm.code,
                    },
                    on: {
                      loading: _vm.setLoading,
                      setenterprise: _vm.setenterprise,
                      activated: _vm.activated,
                    },
                  })
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "router-link",
                    {
                      staticClass: "mt-2 btn btn-secondary btn-block",
                      attrs: { to: { name: "Login" } },
                    },
                    [_vm._v(_vm._s(_vm.$t("BACK")))]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.options.length == 0
        ? _c(
            "div",
            [
              _c(
                "b-overlay",
                { attrs: { show: _vm.redirect, rounded: "sm" } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-fill btn-primary",
                      attrs: { disabled: !_vm.checkAccepted },
                      on: { click: _vm.inviteSubmit },
                    },
                    [_vm._v("\n        " + _vm._s(_vm.$t("NEXT")) + "\n      ")]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }