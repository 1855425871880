var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "sm" } },
        [
          _c(
            "b-media",
            {
              staticClass: "mb-2",
              attrs: { "vertical-align": "center" },
              scopedSlots: _vm._u([
                {
                  key: "aside",
                  fn: function () {
                    return [
                      _c("b-img", {
                        staticClass: "mt-2",
                        attrs: { src: "/img/auth/sefos-certificate.png" },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("p", { staticClass: "mt-4" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("INVITE.NEED_TO_AUTHENTICATE")) +
                    "\n        " +
                    _vm._s(_vm.$t("AUTH_METHODS.sefos-certificate")) +
                    "\n      "
                ),
              ]),
            ]
          ),
          _c(
            "p",
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-fill btn-primary btn-block",
                  attrs: { disabled: !_vm.checkAccepted },
                  on: { click: _vm.inviteSubmit },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("NEXT")) + "\n      ")]
              ),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-block btn-secondary",
                  attrs: { to: { name: "Login" } },
                },
                [_vm._v(_vm._s(_vm.$t("BACK")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }