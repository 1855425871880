<template>
  <section>
    <b-overlay :show="loading" rounded="sm">
     <b-media vertical-align="center" class="mb-2" >
        <template #aside>
          <b-img src="/img/auth/sefos-certificate.png" class="mt-2"></b-img>
        </template>            
        <p class="mt-4">
          {{ $t("INVITE.NEED_TO_AUTHENTICATE") }}
          {{ $t('AUTH_METHODS.sefos-certificate') }}
        </p>
      </b-media>
      <p>
        <button
          :disabled="!checkAccepted"
          v-on:click="inviteSubmit"
          class="btn btn-fill btn-primary btn-block"
        >
          {{ $t("NEXT") }}
        </button>
        <router-link class="btn btn-block btn-secondary" :to="{ name: 'Login' }">{{
          $t("BACK")
        }}</router-link>
      </p>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["code", "accepted", "email"],
  data() {
    return {
      loading: true,
      auth_uuid: '',
      errorMessage: "",
    };
  },
  methods: {
    hideModal: function() {
      this.$emit("hideModal");
    },
    inviteSubmit: async function() {
      this.loading = true;
      let self = this;
      await this.$store.dispatch("errors/removeValidations");
      this.$http
        .post(this.user.hostname + "/invite/activate", {
          code: this.code
        })
        .then(function(result) {
          self.auth_uuid = result.data.auth_uuid;
          self.makeRequest(result.data.request_url);
        })
        .catch(function() {
          self.loading = false;
          self.$emit("loading", false);
        });
    },
    makeRequest: function(url) {
      this.loading = true;
      let self = this;
      this.$http
        .get(url)
        .then(function() {
          self.verifyAuthentication();
        })
        .catch(function() {
          self.$emit("loading", false);
          self.loading = false;
        });
    },
    verifyAuthentication: function() {
      let self = this;
      this.$http
        .get(this.user.hostname + "/invite/activate/result/" + this.auth_uuid)
        .then(async function(result) {
          self.$emit("activated", result.data.token);
        })
        .catch(function() {
          self.$emit("loading", false);
          self.loading = false;
        });
    },
  },
  computed: {
    checkAccepted() {
      return this.accepted == 1;
    },
  },
  mounted: function() {
    this.loading = false;
  },
};
</script>
<style></style>
