<template>
  <section>
    <b-media vertical-align="center" class="mb-2" v-if="!loading">
      <template #aside>
        <b-img src="/img/auth/siths-card.png"  :alt="$t('AUTH_METHODS.siths-card')" class="mt-2"></b-img>
      </template>
      <p class="mt-4">
        {{ $t("INVITE.NEED_TO_AUTHENTICATE") }}
        {{ $t("AUTH_METHODS.siths-card") }}
      </p>
    </b-media>

    <div v-if="options.length != 0">

      <NetIDEnterprise
        v-if="enterprise && enabled_net_id_enterprise"
        :accepted="accepted"
        @loading="setLoading"
        @setaccess="setaccess"
        @activated="activated"
        :code="code"
      ></NetIDEnterprise>

      <div v-if="!loading && enabled_net_id_access && enabled_net_id_enterprise && enterprise">
        <p class="mt-2 mb-2 strikethrough text-center">
          <span>{{ $t("AUTH_METHODS.DO_YOU_HAVE_NET_ID_ACCESS") }}</span>
        </p>
      </div>
    
      <NetIDAccess
        :disabled="!checkAccepted"
        @loading="setLoading"
        v-if="access && enabled_net_id_access"
        :accepted="accepted"
        @setenterprise="setenterprise"
        @activated="activated"
        :code="code"
      ></NetIDAccess>
    
      <router-link
        v-if="!loading"
        class="mt-2 btn btn-secondary btn-block"
        :to="{ name: 'Login' }"
        >{{ $t("BACK") }}</router-link
      >
    </div>

    <div v-if="options.length == 0">
      <b-overlay :show="redirect" rounded="sm">
        <button :disabled="!checkAccepted" class="btn btn-fill btn-primary" @click="inviteSubmit">
          {{ $t("NEXT") }}
        </button>
      </b-overlay>
    </div>
  </section>
</template>
<script>
import NetIDAccess from "@/components/Invite/Activate/Siths/NetIDAccess.vue";
import NetIDEnterprise from "@/components/Invite/Activate/Siths/NetIDEnterprise.vue";
export default {
  props: ["code", "accepted", "email", "auth_method"],
  components: { NetIDAccess, NetIDEnterprise },
  data() {
    return {
      redirect: false,
      enabled_net_id_access: false,
      enabled_net_id_enterprise: false,
      loading: false,
      access: true,
      enterprise: true,
      auth_uuid: "",
      errorMessage: "",
      options: [],
    };
  },
  methods: {
    popuplateMethods() {
      for (let ix = 0; ix < this.methods.length; ix++) {
        let method = this.methods[ix];
        if (method.name == "siths-card") {
          if (method.childs.length > 1) {
            this.options.push({
              value: null,
              text: this.$t("AUTH_METHODS.SELECT_VERIFICATION_METHOD"),
            });
          }
          for (let ix = 0; ix < method.childs.length; ix++) {
            let childMethod = method.childs[ix];
            if (childMethod.name == "net-id-access") {
              if( (this.auth_method == "") || (this.auth_method == "net-id-access")) {
                this.enabled_net_id_access = true;
              }
            }
            if (childMethod.name == "net-id-enterprise") {
              if( (this.auth_method == "") || (this.auth_method == "net-id-enterprise")) {
                this.enabled_net_id_enterprise = true;
              }
            }
            if (method.childs.length == 1) {
              this.authentication_method = childMethod.name;
            }
            if (childMethod.name != "") {
              this.options.push({
                value: childMethod.name,
                text: this.$t("AUTH_METHODS." + childMethod.name),
              });
            }
          }
        }
      }
    },
    inviteSubmit: async function() {
      this.loading = true;
      this.$emit("loading", true);
      let self = this;
      this.$http
        .post(this.user.hostname + "/invite/activate", {
          code: this.code
        })
        .then(function(result) {
          self.loading = false;
          self.redirect = true;
          window.location.href = result.data.request_url;
        })
        .catch(function() {
          self.$emit("loading", false);
          self.loading = false;
        });
    },
    setLoading(value) {
      this.loading = value;
      this.$emit("loading", value);
    },
    activated(data) {
      this.$emit("activated", data);
    },
    setaccess(val) {
      this.access = val;
    },
    setenterprise(val) {
      this.enterprise = val;
    },
    checkMethods(item) {
      for (let ix = 0; ix < this.options.length; ix++) {
        if (this.options[ix].value == item) {
          return true;
        }
      }
      return false;
    },
    async getMethods() {
      let self = this;
      await this.$http
        .get(this.user.hostname + "/authenticate/external/methods")
        .then(function(result) {
          self.methods = result.data;
          self.popuplateMethods();
        })
        .catch(function() {
          self.loading = false;
        });
    },
  },
  computed: {
    showBack() {
      return this.authentication_method == null && this.auth_method == "";
    },
    checkAccepted() {
      return this.accepted == 1;
    },
  },
  mounted: function() {
    this.getMethods();
  },
};
</script>
<style></style>
