<template>
  <section class="mt-2">
    <div v-if="!loading">
      <p>
        <b-overlay :show="redirect" rounded="sm">
          <button
            :disabled="!checkAccepted"
            v-on:click="inviteSubmit"
            class="btn btn-fill btn-primary btn-block"
          >
            {{ $t("NEXT") }}
          </button>
          <router-link class="btn btn-secondary btn-block mt-2" :to="{ name: 'Login' }">{{
            $t("BACK")
          }}</router-link>

        </b-overlay>
      </p>
    </div>
  </section>
</template>
<script>
export default {
  components: {},
  props: ["code", "accepted", "email"],
  data() {
    return {
      redirect: false,
      loading: true,
      auth_uuid: "",
    };
  },
  methods: {
    async inviteSubmit() {
      this.loading = true;
      this.$emit("loading", true);
      let self = this;
      await this.$store.dispatch("errors/removeValidations");
      this.$http
        .post(this.user.hostname + "/invite/activate", {
          code: this.code
        })
        .then(function(result) {
          self.loading = false;
          self.redirect = true;
          window.location.href = result.data.request_url;
        })
        .catch(function() {
          self.loading = false;
          self.redirect = false;
        });
    },
  },
  computed: {
    checkAccepted() {
      return this.accepted == 1;
    }
  },
  mounted: function() {
    this.loading = false;
  },
};
</script>
<style></style>
