var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "sm" } },
        [
          _c("p", { staticClass: "mt-4" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("INVITE.NEED_TO_SET_PASSWORD")) +
                "\n    "
            ),
          ]),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { staticClass: "col-form-label" }, [
                _vm._v(_vm._s(_vm.$t("PASSWORD"))),
              ]),
              _c("b-form-input", {
                attrs: { trim: "", type: "password", autocomplete: "off" },
                on: { input: _vm.acceptData },
                model: {
                  value: _vm.password1,
                  callback: function ($$v) {
                    _vm.password1 = $$v
                  },
                  expression: "password1",
                },
              }),
              _c(
                "b-form-invalid-feedback",
                { attrs: { state: _vm.errors.length == 0 } },
                _vm._l(_vm.errors, function (item, index) {
                  return _c("div", { key: index }, [
                    _vm._v("\n                    * " + _vm._s(item)),
                  ])
                }),
                0
              ),
              _c("label", { staticClass: "col-form-label" }, [
                _vm._v(_vm._s(_vm.$t("RETYPE_PASSWORD"))),
              ]),
              _c("b-form-input", {
                attrs: { trim: "", type: "password", autocomplete: "off" },
                model: {
                  value: _vm.password2,
                  callback: function ($$v) {
                    _vm.password2 = $$v
                  },
                  expression: "password2",
                },
              }),
              _c(
                "b-form-invalid-feedback",
                { attrs: { state: !_vm.password_not_matching } },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("ERROR.PASSWORD_NOT_MATCHED")) +
                      "\n          "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-fill btn-block btn-primary",
              attrs: { disabled: !_vm.showNextButton },
              on: { click: _vm.inviteSubmit },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("NEXT")) + "\n    ")]
          ),
          _c(
            "router-link",
            {
              staticClass: "btn-block btn btn-secondary",
              attrs: { to: { name: "Login" } },
            },
            [_vm._v(_vm._s(_vm.$t("BACK")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }